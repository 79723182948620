import React from "react";

import { SVGProps } from "react";

/**
 * @type BoxLabelIconProps
 */
export type BoxLabelIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const BoxLabelIcon
 */
const BoxLabelIcon = (props: BoxLabelIconProps) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="46.561" height="42" viewBox="0 0 46.561 42">
            <path d="M18.375,10.5V0H7.875V21h31.5V0h-10.5V10.5l-5.25-2.625ZM11.484,26.824,5.644,31.5H0V42H32.517l.837-.574,10.5-7.219,2.707-1.862-3.716-5.406L40.138,28.8l-7.752,5.332H21V31.5H31.5V26.25H12.206Z" fill="currentColor"/>
        </svg>

    );
};

export default BoxLabelIcon;
