import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import FingerPrintBadgeIcon from "@js/component/icon/badge/FingerPrintBadgeIcon";
import SocialBadgeIcon from "@js/component/icon/badge/SocialBadgeIcon";
import SmileBadgeIcon from "@js/component/icon/badge/SmileBadgeIcon";
import ForwardBadgeIcon from "@js/component/icon/badge/ForwardBadgeIcon";
import Heading from "@js/component/Heading";

import * as classNames from "@css/component/block/BlockOurCoreValues.module.scss";
import PiggyBadgeIcon from "@js/component/icon/badge/PiggyBadgeIcon";
import RocketBadgeIcon from "@js/component/icon/badge/RocketBadgeIcon";
import BadgeCheckBadgeIcon from "@js/component/icon/badge/BadgeCheckBadgeIcon";
import UserCrownBadgeIcon from "@js/component/icon/badge/UserCrownBadgeIcon";

/**
 * @type BlockOurCoreValuesProps
 */
export type BlockOurCoreValuesProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const BlockOurCoreValues
 */
const BlockOurCoreValues = (props: BlockOurCoreValuesProps) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <section { ...restProps } className={ clsx(classNames.blockOurCoreValues, className) }>
            <div className={ classNames.container }>
                <Heading className={ classNames.heading } element="h2" variant="large">Onze <strong>kernwaarden</strong></Heading>
                <div className={ classNames.blocks }>
                    <div className={ classNames.block }>
                        <PiggyBadgeIcon className={ classNames.icon } />
                        <Heading className={ classNames.heading } element="h2" variant="small">Voordelig</Heading>
                        <p className={ classNames.paragraph }>
                            Bij ons vergelijk je alle gecertificeerde adviseurs bij jou in de buurt onafhankelijk op prijs, levertijd en beoordeling. Zo profiteer jij gegarandeerd van de beste deal en de scherpste prijs van Nederland. Daarnaast betaal je pas bij ontvangst van het energielabel (en optioneel maatwerkadvies) en wordt deze voor je geregistreerd bij de overheid.
                        </p>
                    </div>
                    <div className={ classNames.block }>
                        <RocketBadgeIcon className={ classNames.icon } />
                        <Heading className={ classNames.heading } element="h2" variant="small">Snel</Heading>
                        <p className={ classNames.paragraph }>
                            Logischerwijs is de energieprestatiemarkt niet een markt waarin je direct thuis bent. Wij proberen daarom het aanvragen van een energielabel (en optioneel maatwerkadvies) voor jou zo eenvoudig mogelijk te maken. Door onze meest volledige vergelijking van Nederland profiteer je van snelheid & flexibiliteit en kun jij je energielabel (en optioneel maatwerkadvies) al binnen één werkdag ontvangen.
                        </p>
                    </div>
                    <div className={ classNames.block }>
                        <BadgeCheckBadgeIcon className={ classNames.icon } />
                        <Heading className={ classNames.heading } element="h2" variant="small">Betrouwbaar</Heading>
                        <p className={ classNames.paragraph }>
                            Uniek aan ons is dat we écht weten waar we over praten. Ons team heeft gezamenlijk meer dan 20 jaar ervaring in de energieprestatiemarkt. Vanuit onze kennis gedreven aanpak dragen wij als de officiële vergelijkingswebsite voor energielabels en maatwerkadviezen dan ook graag bij aan maatschappelijk belang. Dit doen wij onder andere door de energieprestatiemarkt transparant te maken.
                        </p>
                    </div>
                    <div className={ classNames.block }>
                        <UserCrownBadgeIcon className={ classNames.icon } />
                        <Heading className={ classNames.heading } element="h2" variant="small">Klantgericht</Heading>
                        <p className={ classNames.paragraph }>
                            Wij zijn altijd op de hoogte van de laatste ontwikkelingen in de markt, zo vind je bij ons alle informatie over energielabels en maatwerkadviezen. Heb je toch vragen? Wij zijn telefonisch, per e-mail of via Whatsapp bereikbaar voor al je vragen. Klaar voor het aanvragen? Vergelijk, selecteer en klaar. Offertes opvragen is verleden tijd.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlockOurCoreValues;
