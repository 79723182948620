import React from "react";

import { SVGProps } from "react";

/**
 * @type GroupLabelIconProps
 */
export type GroupLabelIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const GroupLabelIcon
 */
const GroupLabelIcon = (props: GroupLabelIconProps) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="52.642" height="42.113" viewBox="0 0 52.642 42.113">
            <path d="M11.844,13.16a6.58,6.58,0,1,1,6.58-6.58A6.578,6.578,0,0,1,11.844,13.16Zm30.269,0a6.58,6.58,0,1,1,6.58-6.58A6.578,6.578,0,0,1,42.113,13.16ZM3.948,15.792H16.121a10.683,10.683,0,0,0-.329,2.632,10.532,10.532,0,0,0,3.562,7.9H0ZM52.642,26.321H33.288a10.5,10.5,0,0,0,3.562-7.9,10.683,10.683,0,0,0-.329-2.632H48.693Zm-18.425-7.9a7.9,7.9,0,1,1-7.9-7.9A7.9,7.9,0,0,1,34.217,18.425Zm3.948,10.528,3.948,13.16H10.528l3.948-13.16Z" fill="currentColor"/>
        </svg>

    );
};

export default GroupLabelIcon;
