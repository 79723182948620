import React from "react";

import { SVGProps } from "react";

/**
 * @type RocketBadgeIconProps
 */
export type RocketBadgeIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const RocketBadgeIcon
 */
const RocketBadgeIcon = (props: RocketBadgeIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" width="45.013" height="45.013" viewBox="0 0 45.013 45.013">
            <path d="M33.759,26.8V37.969L19.693,45V32.519a7.2,7.2,0,0,0-7.2-7.209L0,25.274,7.033,11.243H18.2C25.856-1.373,37.962-.748,44.195.808,45.751,7.041,46.375,19.147,33.759,26.8Zm3.517-15.561a3.517,3.517,0,1,0-3.517,3.517A3.517,3.517,0,0,0,37.276,11.243ZM14.638,41.31C10.286,45.662.044,44.959.044,44.959s-.7-10.242,3.648-14.594A7.74,7.74,0,0,1,14.638,41.31Zm-4.106-3.2a2.574,2.574,0,0,0-3.64-3.64c-1.451,1.451-1.213,4.853-1.213,4.853s3.4.237,4.853-1.213Z" transform="translate(0 0.011)" fill="currentColor"/>
        </svg>
    );
};

export default RocketBadgeIcon;
