// extracted by mini-css-extract-plugin
export var _break = "BlockSearchAdvisors-module--_break--C0rOu";
export var advertorial = "BlockSearchAdvisors-module--advertorial--uWVym";
export var blockSearchAdvisors = "BlockSearchAdvisors-module--blockSearchAdvisors---mUPs";
export var container = "BlockSearchAdvisors-module--container--UW1U9";
export var content = "BlockSearchAdvisors-module--content--Cr6RF";
export var featuredImage = "BlockSearchAdvisors-module--featuredImage--5WZgX";
export var gatsbyImageWrapper = "BlockSearchAdvisors-module--gatsby-image-wrapper--K6QyQ";
export var headerContent = "BlockSearchAdvisors-module--headerContent--v7IkT";
export var heading = "BlockSearchAdvisors-module--heading--2z0NT";
export var image = "BlockSearchAdvisors-module--image--9YLHJ";
export var label = "BlockSearchAdvisors-module--label--ohma9";
export var usps = "BlockSearchAdvisors-module--usps--B+Q72";