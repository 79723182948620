// extracted by mini-css-extract-plugin
export var block = "BlockHowDoesItWork-module--block--s2pMG";
export var blockHowDoesItWork = "BlockHowDoesItWork-module--blockHowDoesItWork--RVmn7";
export var blocks = "BlockHowDoesItWork-module--blocks--uYYmz";
export var container = "BlockHowDoesItWork-module--container--Xajw4";
export var heading = "BlockHowDoesItWork-module--heading--yOgBc";
export var icon = "BlockHowDoesItWork-module--icon--YSFFL";
export var linkButton = "BlockHowDoesItWork-module--linkButton--S9Wcl";
export var linkButtonContainer = "BlockHowDoesItWork-module--linkButtonContainer--6b217";
export var paragraph = "BlockHowDoesItWork-module--paragraph--Q5N36";
export var text = "BlockHowDoesItWork-module--text--PpVIo";