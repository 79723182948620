// extracted by mini-css-extract-plugin
export var Usps = "Index-module--Usps--CqFWe";
export var _break = "Index-module--_break--5Dggx";
export var advertorial = "Index-module--advertorial--Eosdh";
export var container = "Index-module--container--JinHq";
export var content = "Index-module--content--bdsYA";
export var featuredImage = "Index-module--featuredImage--fM4De";
export var gatsbyImageWrapper = "Index-module--gatsby-image-wrapper--hvKl-";
export var headerContent = "Index-module--headerContent--4v94q";
export var heading = "Index-module--heading--MpvDV";
export var image = "Index-module--image--m2-kD";
export var label = "Index-module--label--Bg+QX";
export var main = "Index-module--main--XQ07g";