import React from "react";

import { SVGProps } from "react";

/**
 * @type PiggyBadgeIconProps
 */
export type PiggyBadgeIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const PiggyBadgeIcon
 */
const PiggyBadgeIcon = (props: PiggyBadgeIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" width="50" height="44.444" viewBox="0 0 50 44.444">
            <path d="M34.722,8.333v.061c-.46-.035-.92-.061-1.389-.061H22.222a16.772,16.772,0,0,0-4.149.521c-.009-.174-.017-.347-.017-.521a8.333,8.333,0,1,1,16.667,0Zm-1.389,2.778c.3,0,.608.009.9.026.365.026.729.061,1.094.113a8.341,8.341,0,0,1,6.337-2.917h2.778l-1.632,6.519a14.006,14.006,0,0,1,3.255,4.592H50V33.333H44.444a14.036,14.036,0,0,1-2.778,2.778v8.333H33.333V38.889H22.222v5.556H13.889V36.111A13.856,13.856,0,0,1,8.4,26.389H5.9a5.9,5.9,0,0,1,0-11.806H6.944V18.75H5.9a1.736,1.736,0,1,0,0,3.472H8.611A13.888,13.888,0,0,1,22.222,11.111Zm5.556,11.806A2.083,2.083,0,1,0,36.806,25a2.083,2.083,0,0,0,2.083-2.083Z" fill="currentColor"/>
        </svg>
    );
};

export default PiggyBadgeIcon;
