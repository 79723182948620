import React from "react";
import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { createRef } from "react";
import Moment from "react-moment";
import 'moment/locale/nl';

import {graphql, Link, useStaticQuery} from "gatsby";

import clsx from "clsx";

import Heading from "@js/component/Heading";

import * as classNames from "@css/component/block/BlockFAQ.module.scss";
import SiteFaqs from "@js/component/SiteFaqs";
import LongArrowRightIcon from "@js/component/icon/LongArrowRightIcon";
import LinkButton from "@js/component/LinkButton";

/**
 * @type BlockFAQProps
 */
type BlockFAQProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @type AllFaqData
 */
type AllFaqData = {
    allWordpressFaq: {
        edges: {
            node: {
                content: string;
                title: string;
            };
        }[];
    }
};

/**
 * @const BlockFAQ
 */
const BlockFAQ = (props: BlockFAQProps) => {
    const {
        className,
        ...restProps
    } = props;

    const data: AllFaqData = useStaticQuery(graphql`
        query {
            allWordpressFaq {
                edges {
                    node {
                        content
                        title
                    }
                }
            }
        }
    `);

    const FaqResults = data.allWordpressFaq.edges.slice(0,5);

    return (
        data.allWordpressFaq.edges.length > 0 &&
            <section {...restProps} className={classNames.blockFAQ}>
                <div className={classNames.container}>
                    <Heading className={classNames.heading} element="h2" variant="large">
                        Veel gestelde <strong>vragen</strong>
                    </Heading>
                    <div className={classNames.faqs}>
                        <SiteFaqs faqs={FaqResults} variant="home"/>

                        {data.allWordpressFaq.edges.length > 6 &&
                            <LinkButton className={classNames.linkButton} to="/veelgestelde-vragen" variant="white">
                                <span>Toon meer vragen</span>
                            </LinkButton>
                        }
                    </div>
                </div>
            </section>
    );
};

export default BlockFAQ;
