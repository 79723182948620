// extracted by mini-css-extract-plugin
export var blockAdvisorReviews = "BlockAdvisorReviews-module--blockAdvisorReviews--KDudo";
export var button = "BlockAdvisorReviews-module--button--82qeo";
export var buttons = "BlockAdvisorReviews-module--buttons--H0bQ3";
export var container = "BlockAdvisorReviews-module--container--bz4qi";
export var date = "BlockAdvisorReviews-module--date--ADt7Q";
export var dateWrapper = "BlockAdvisorReviews-module--dateWrapper--VPijy";
export var fill = "BlockAdvisorReviews-module--fill--BNxYu";
export var heading = "BlockAdvisorReviews-module--heading--OG3W1";
export var icon = "BlockAdvisorReviews-module--icon--alzbl";
export var logo = "BlockAdvisorReviews-module--logo--lqkZ1";
export var logoLabel = "BlockAdvisorReviews-module--logoLabel--3PeLa";
export var logoWrapper = "BlockAdvisorReviews-module--logoWrapper--I2EE7";
export var paragraph = "BlockAdvisorReviews-module--paragraph--VaoO6";
export var review = "BlockAdvisorReviews-module--review--ZSO2C";
export var slide = "BlockAdvisorReviews-module--slide--eNOeE";
export var stars = "BlockAdvisorReviews-module--stars--5varj";
export var starsAndLogo = "BlockAdvisorReviews-module--starsAndLogo--00JJH";
export var truncated = "BlockAdvisorReviews-module--truncated--X8tfC";