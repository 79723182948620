import React from "react";

import { SVGProps } from "react";

/**
 * @type CompareLabelIconProps
 */
export type CompareLabelIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const CompareLabelIcon
 */
const CompareLabelIcon = (props: CompareLabelIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" width="50.807" height="41.036" viewBox="0 0 50.807 41.036">
            <path d="M17.953,20.518A10.259,10.259,0,1,0,7.694,10.259,10.259,10.259,0,0,0,17.953,20.518ZM35.907,41.036l-.793-2.589a12.824,12.824,0,0,1-12.03-12.8c0-.433.024-.858.064-1.282H5.13L0,41.036Zm0-21.793a6.412,6.412,0,1,1-6.412,6.412,6.412,6.412,0,0,1,6.412-6.412Zm0,16.671a10.255,10.255,0,0,0,5.771-1.771l5.057,5.025L48.1,40.523,50.807,37.8l-1.363-1.355-5.049-5.017a10.259,10.259,0,1,0-8.488,4.5Z" fill="currentColor"/>
        </svg>
    );
};

export default CompareLabelIcon;
