import React from "react";

import { SVGProps } from "react";

/**
 * @type BadgeCheckBadgeIconProps
 */
export type BadgeCheckBadgeIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const BadgeCheckBadgeIcon
 */
const BadgeCheckBadgeIcon = (props: BadgeCheckBadgeIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" width="46.586" height="46.586" viewBox="0 0 46.586 46.586">
            <path id="badge-check-sharp-solid" d="M23.293,0a8.735,8.735,0,0,1,7.725,4.649A8.739,8.739,0,0,1,41.936,15.568a8.742,8.742,0,0,1,0,15.45A8.739,8.739,0,0,1,31.018,41.936a8.742,8.742,0,0,1-15.45,0A8.739,8.739,0,0,1,4.649,31.018a8.742,8.742,0,0,1,0-15.45A8.739,8.739,0,0,1,15.568,4.649,8.735,8.735,0,0,1,23.293,0ZM33.575,19.017l1.547-1.547-3.094-3.084-1.547,1.547-10.1,10.1L16.1,21.755l-1.547-1.547-3.084,3.084L13.02,24.84l5.823,5.823L20.39,32.21l1.547-1.547Z" fill="currentColor"/>
        </svg>
    );
};

export default BadgeCheckBadgeIcon;
