import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import Heading from "@js/component/Heading";
import LinkButton from "@js/component/LinkButton";
import LongArrowRightIcon from "@js/component/icon/LongArrowRightIcon";

import * as classNames from "@css/component/block/BlockCTA.module.scss";

/**
 * @type BlockCTAProps
 */
export type BlockCTAProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const BlockWhoWeAre
 */
const BlockCTA = (props: BlockCTAProps) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <section { ...restProps } className={ clsx(classNames.blockCTA, className) }>
            <div className={ classNames.container }>
                <div className={ classNames.block }>
                    <Heading className={ classNames.heading } element="h2" variant="large">
                        <strong>Energielabel</strong> aanvragen? <strong>Maatwerkadvies</strong> aanvragen?
                    </Heading>
                    <p className={ classNames.paragraph }>
                        Vergelijk alle adviseurs die bij jou in de buurt werkzaam zijn op prijs, levertijd en beoordeling om te profiteren van de beste deal en een korte levertijd!
                        Wij werken enkel met gecertificeerde adviseurs en je betaalt pas na ontvangst van jouw energielabel (en optioneel maatwerkadvies).
                        <strong> Let op:</strong> Het maatwerkadvies wordt altijd opgesteld in combinatie met een energielabel, zodoende dat je de producten samen aanvraagt.
                        Het energielabel fungeert in deze als de 0-meting en het maatwerkadvies beschrijft hoe je kunt verbeteren vanaf dit punt.
                        Start jouw vergelijking meteen via de onderstaande knop!
                    </p>
                    <LinkButton className={ classNames.linkButton } to="/vergelijk" variant="white">
                        <span>Nu aanvragen</span>
                        <LongArrowRightIcon className={ classNames.icon } />
                    </LinkButton>
                </div>
            </div>
        </section>
    );
};

export default BlockCTA;
