import React from "react";

import { SVGProps } from "react";

/**
 * @type UserCrownBadgeIconProps
 */
export type UserCrownBadgeIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const UserCrownBadgeIcon
 */
const UserCrownBadgeIcon = (props: UserCrownBadgeIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" width="42.472" height="48.539" viewBox="0 0 42.472 48.539">
            <path id="user-crown-sharp-solid_1_" data-name="user-crown-sharp-solid (1)" d="M9.1,5.091V0l4.551,2.275,1.517.758L21.236,0,27.3,3.034l1.517-.758L33.371,0V13.652a12.135,12.135,0,0,1-24.27,0Zm4.551,7.044v1.517a7.584,7.584,0,0,0,15.168,0V12.135ZM36.4,28.82l6.067,19.719H0L6.067,28.82Z" fill="currentColor"/>
        </svg>
    );
};

export default UserCrownBadgeIcon;
