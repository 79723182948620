import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import { DetailedHTMLProps } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import Heading from "@js/component/Heading";
import LinkButton from "@js/component/LinkButton";
import LongArrowRightIcon from "@js/component/icon/LongArrowRightIcon";

import * as classNames from "@css/component/block/BlockAboutEnergyLabels.module.scss";

/**
 * @type FileData
 */
type FileData = {
    file: {
        childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
        };
    };
};

/**
 * @type BlockAboutEnergylabelsProps
 */
export type BlockAboutEnergylabelsProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const BlockWhoWeAre
 */
const BlockAboutEnergylabels = (props: BlockAboutEnergylabelsProps) => {
    const {
        className,
        ...restProps
    } = props;

    const data: FileData = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "about-energy-labels.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 520, quality: 75)
                }
            }
        }
    `);

    return (
        <section { ...restProps } className={ clsx(classNames.blockAboutEnergylabels, className) }>
            <div className={ classNames.container }>
                <div className={ classNames.blocks }>
                    <div className={ classNames.block }>
                        <Heading className={ classNames.heading } element="h2" variant="large">Over <strong>energielabels</strong></Heading>
                        <p className={ classNames.paragraph }>
                            Waarom bestaat het energielabel zoals we die kennen? Dat heeft alles te maken met de opwarming van de aarde.
                            Daardoor is het energielabel een wettelijke verplichting vanuit de overheid. De aarde warmt op!
                            Dit komt doordat we broeikasgassen zoals CO2 op allerlei manieren uitstoten. Voor een groot deel komt deze CO2 uit jouw woning.
                            Omdat de aarde opwarmt en de CO2 voor een groot deel uit jouw woning komt is de regelgeving voor energielabels in het leven geroepen.
                            Zo is de energieprestatie van jouw woning gemakkelijk inzichtelijk.
                            De overheid wil met de regelgeving voor energielabels gebouweigenaren informeren over de energiezuinigheid van een woning of bedrijfspand.
                            Ook wil de overheid hiermee stimuleren om energiebesparende maatregelen te nemen zoals de isolatie of het plaatsen van zonnepanelen.
                        </p>
                        <LinkButton className={ classNames.linkButton } to="/over-energielabels" variant="green">
                            <span>Meer over energielabels</span>
                            <LongArrowRightIcon className={ classNames.icon } />
                        </LinkButton>
                    </div>
                    <div className={ classNames.block }>
                        <GatsbyImage
                            alt="over ons"
                            className={ classNames.image }
                            image={ data.file.childImageSharp.gatsbyImageData }
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlockAboutEnergylabels;
