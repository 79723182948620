
const useHtmlDecode = (html: string) => {
    if (typeof window === 'undefined' || !window.document) {
        return;
    }

    const element = document.createElement('div');
    return html.replace(/&[#0-9a-z]+;/gi, function (enc) {
        element.innerHTML = enc;
        return element.innerText;
    });
};

export default useHtmlDecode;