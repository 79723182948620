import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import { DetailedHTMLProps } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import Heading from "@js/component/Heading";
import LinkButton from "@js/component/LinkButton";
import LongArrowRightIcon from "@js/component/icon/LongArrowRightIcon";

import * as classNames from "@css/component/block/BlockWhoWeAre.module.scss";

/**
 * @type FileData
 */
type FileData = {
    file: {
        childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
        };
    };
};

/**
 * @type BlockWhoWeAreProps
 */
export type BlockWhoWeAreProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const BlockWhoWeAre
 */
const BlockWhoWeAre = (props: BlockWhoWeAreProps) => {
    const {
        className,
        ...restProps
    } = props;

    const data: FileData = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "about-us.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 520, quality: 75)
                }
            }
        }
    `);

    return (
        <section { ...restProps } className={ clsx(classNames.blockWhoWeAre, className) }>
            <div className={ classNames.container }>
                <div className={ classNames.blocks }>
                    <div className={ classNames.block }>
                        <Heading className={ classNames.heading } element="h2" variant="large">Wij zijn <strong>Woninglabel.nl</strong></Heading>
                        <p className={ classNames.paragraph }>
                            Woninglabel.nl is een initiatief van adviseurs met ruim 20 jaar ervaring in de energieprestatiemarkt.
                            Het Woninglabel.nl Team staat voor je klaar om jou gemakkelijk en snel aan een energielabel (en optioneel maatwerkadvies) te helpen.
                            Dit doen we door alle adviseurs bij jou in de buurt overzichtelijk weer te geven en alle informatie over energielabels en maatwerkadviezen te verzamelen op één overzichtelijke plek.
                            We staan voor het makkelijk maken van moeilijke dingen.
                            Daarom beoordelen onze klanten ons gemiddeld met 4,9 uit 5 sterren.
                            Hier zijn we trots op en dit geeft ons de energie om jou de beste service te verlenen!
                        </p>
                        <LinkButton className={ classNames.linkButton } to="/over-ons" variant="white">
                            <span>Meer over ons</span>
                            <LongArrowRightIcon className={ classNames.icon } />
                        </LinkButton>
                    </div>
                    <div className={ classNames.block }>
                        <GatsbyImage
                            alt="over ons"
                            className={ classNames.image }
                            image={ data.file.childImageSharp.gatsbyImageData }
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlockWhoWeAre;
